<template>
    <div class="plate-center">
        <!-- 面包屑 S -->
        <div class="yh-h88 level_align yh-fz14">
            当前位置：
            <el-breadcrumb class="breadcrumb" separator-class="el-icon-arrow-right">
                <el-breadcrumb-item
                    v-for="(item, index) in routerList"
                    :key="index"
                    :to="{ path: item.path }"
                >{{ item.name }}</el-breadcrumb-item>
                <el-breadcrumb-item>
                    <span class="yh-fc-blue-dan">{{ pageName }}</span>
                </el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <!-- 面包屑 E -->
        <!-- 富文本详情 S-->
        <h3 class="yh-tc yh-lh56" v-if="title">{{title}}</h3>
        <p class="yh-tc yh-yh-lh40 yh-mb20 yh-fc666" v-if="create_time">{{create_time}}</p>
        <div v-html="fwb"></div>
        <!-- 富文本详情 S-->
    </div>
</template>
<script>
export default {
    name: "RichTextDetails",
    data() {
        return {
            routerList: [], // 面包屑的路由
            id: "",
            pageName: "详情", // 当前页面名字
            pageApi: "", // 页面请求的api
            fwb: "", // 富文本
            title: "", // 标题
            create_time: "", // 时间
        };
    },
    created() {
        this.routerList =
            this.$route.query.router && JSON.parse(this.$route.query.router);
        this.pageName = this.$route.query.pageName;
        this.id = this.$route.query.id || "";
        this.pageApi = this.$route.query.api || "";
        this.fwb =
            this.$route.query.fwb &&
            this.parseRichImgStyle(this.$route.query.fwb);
        if (this.pageApi) {
            this.getInfo();
        }
    },
    methods: {
        //获取数据
        getInfo() {
            this.$axios
                .post(this.$api[this.pageApi], { id: this.id })
                .then((res) => {
                    if (res.code == 1) {
                        if (this.pageApi == "newsDetail") {
                            this.title = res.data.title;
                            this.create_time = res.data.create_time;
                            this.fwb = this.parseRichImgStyle(res.data.detail);
                        } else if (this.pageApi == "newshelpDetail") {
                            this.title = res.data.title;
                            this.create_time = res.data.create_time;
                            this.fwb = res.data.detail;
                        } else {
                            this.fwb = this.parseRichImgStyle(res.data.value);
                        }
                    }
                });
        },
    },
};
</script>



<style scoped lang="scss">
</style>
